import PropTypes from 'prop-types'
import React from 'react'
import spring from '../images/spring.jpg'
import summer from '../images/summer.jpg'
import autumn from '../images/autumn.jpg'
import winter from '../images/winter.jpg'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Intro</h2>
          <span className="image main">
            <img src={spring} alt="vår" />
          </span>
          <p>
            Thomas Bruusgaard er en svært erfaren teknolog med over 20 års erfaring. Han har vært involvert i flere store prosjekter for firmaer innenlands og utenlands. Spisskompetanse innen arkitektur og utvikling av tjenesteorienterte løsninger (SOA og event-drevet). Brenner for smidige utviklingsmetoder og velkjente prinsipper som SOLID. Meget produktiv og erfaren konsulent innen både windows- og web-løsninger.
          </p>
          <p>
            Thomas er partner i <a href="http://boitano.no" target="_blank" rel="noopener noreferrer">Boitano AS</a>.
          </p>
          {close}
        </article>

        <article
          id="work"
          className={`${this.props.article === 'work' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Erfaring</h2>
          <span className="image main">
            <img src={summer} alt="sommer" />
          </span>
          <p>
            Dette er et knippe av kundene jeg har jobbet hos:
          </p>
          <ul>
            <li>
              NRK
            </li>
            <li>
              GuardRec
            </li>
            <li>
              If
            </li>
            <li>
              Kredinor
            </li>
            <li>
              Nordea
            </li>
            <li>
              British Telecom
            </li>
          </ul>
            
          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Om</h2>
          <span className="image main">
            <img src={autumn} alt="høst" />
          </span>
          <p>
            Du kan lese mer om hvordan det går med <a href="https://proff.no/selskap/bruusi-holding-as/vettre/bedriftsr%C3%A5dgivning/IF8D26D043Z/" target="_blank" rel="noopener noreferrer">Bruusi Holding AS her</a>.
          </p>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Kontakt</h2>
          <span className="image main">
            <img src={winter} alt="vinter" />
          </span>
          <p>
            Du finner meg på <a href="https://www.linkedin.com/in/thomasbruusgaard" target="_blank" rel="noopener noreferrer">LinkedIn</a>.
          </p>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
